const CREATE_USER = '@userTypes/CREATE_USER';
const CREATE_USER_SUCCESS = '@userTypes/CREATE_USER_SUCCESS';
const CREATE_USER_ERROR = '@userTypes/CREATE_USER_ERROR';
const GET_USER = '@userTypes/GET_USER';
const GET_USER_SUCCESS = '@userTypes/GET_USER_SUCCESS';
const GET_USER_ERROR = '@userTypes/GET_USER_ERROR';
const GET_USER_COLLECTION = '@userTypes/GET_USER_COLLECTION';
const GET_USER_COLLECTION_SUCCESS = '@userTypes/GET_USER_COLLECTION_SUCCESS';
const GET_USER_COLLECTION_ERROR = '@userTypes/GET_USER_COLLECTION_ERROR';
const GET_USER_COLLECTION_DETAILED = '@userTypes/GET_USER_COLLECTION_DETAILED';
const GET_USER_COLLECTION_DETAILED_SUCCESS =
  '@userTypes/GET_USER_COLLECTION_DETAILED_SUCCESS';
const GET_USER_COLLECTION_DETAILED_ERROR =
  '@userTypes/GET_USER_COLLECTION_DETAILED_ERROR';
const GET_USER_CREATED_NFTS = '@userTypes/GET_USER_CREATED_NFTS';
const GET_USER_CREATED_NFTS_SUCCESS =
  '@userTypes/GET_USER_CREATED_NFTS_SUCCESS';
const GET_USER_CREATED_NFTS_ERROR = '@userTypes/GET_USER_CREATED_NFTS_ERROR';
const GET_USER_OWNED_NFTS = '@userTypes/GET_USER_OWNED_NFTS';
const GET_USER_OWNED_NFTS_SUCCESS = '@userTypes/GET_USER_OWNED_NFTS_SUCCESS';
const GET_USER_OWNED_NFTS_ERROR = '@userTypes/GET_USER_OWNED_NFTS_ERROR';
const GET_USER_ON_SALE_NFTS = '@userTypes/GET_USER_ON_SALE_NFTS';
const GET_USER_ON_SALE_NFTS_SUCCESS =
  '@userTypes/GET_USER_ON_SALE_NFTS_SUCCESS';
const GET_USER_ON_SALE_NFTS_ERROR = '@userTypes/GET_USER_ON_SALE_NFTS_ERROR';
const GET_USER_LISTINGS = '@userTypes/GET_USER_LISTINGS';
const GET_USER_LISTINGS_SUCCESS = '@userTypes/GET_USER_LISTINGS_SUCCESS';
const GET_USER_LISTINGS_ERROR = '@userTypes/GET_USER_LISTINGS_ERROR';
export {
  CREATE_USER,
  CREATE_USER_SUCCESS,
  CREATE_USER_ERROR,
  GET_USER,
  GET_USER_SUCCESS,
  GET_USER_ERROR,
  GET_USER_COLLECTION,
  GET_USER_COLLECTION_SUCCESS,
  GET_USER_COLLECTION_ERROR,
  GET_USER_CREATED_NFTS,
  GET_USER_CREATED_NFTS_SUCCESS,
  GET_USER_CREATED_NFTS_ERROR,
  GET_USER_OWNED_NFTS,
  GET_USER_OWNED_NFTS_SUCCESS,
  GET_USER_OWNED_NFTS_ERROR,
  GET_USER_COLLECTION_DETAILED,
  GET_USER_COLLECTION_DETAILED_SUCCESS,
  GET_USER_COLLECTION_DETAILED_ERROR,
  GET_USER_ON_SALE_NFTS,
  GET_USER_ON_SALE_NFTS_SUCCESS,
  GET_USER_ON_SALE_NFTS_ERROR,
  GET_USER_LISTINGS,
  GET_USER_LISTINGS_SUCCESS,
  GET_USER_LISTINGS_ERROR,
};
