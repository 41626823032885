import React, { useState } from 'react';
const Tabs = ({ tabs, onChange }) => {
  const [activeTab, setActiveTab] = useState(0);
  const handleTabClick = (index, tab) => {
    setActiveTab(index);
    onChange(tab.value);
  };
  return (
    <div className="flex flex-row gap-[10px]">
      {tabs.map((tab, index) => (
        <div
          key={index}
          className={`cursor-pointer text-[18px] leading-[18px] font-normal font-archivo px-[18px] py-[12px]  rounded-[40px]  ${
            activeTab === index
              ? 'text-[#0E0E0E] bg-[#FFF]'
              : 'text-[#FFF] border-[1px] border-[#ffffff33] hover:border-white'
          }`}
          onClick={() => handleTabClick(index, tab)}
        >
          {tab.title}
        </div>
      ))}
    </div>
  );
};
export default Tabs;
