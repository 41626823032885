import { transactionTypes } from '../types';

const INIT_STATE = {
  list: [],
  error: null,
  loading: false,
};

const transactionReducer = (state = INIT_STATE, action) => {
  const { type, payload } = action;
  switch (type) {
    case transactionTypes.GET_TRANSACTIONS:
      return { ...state, loading: true };
    case transactionTypes.GET_TRANSACTIONS_SUCCESS:
      return { ...state, list: payload, loading: false };
    case transactionTypes.GET_TRANSACTIONS_ERROR:
      return { ...state, error: payload, loading: false };
    default:
      return state;
  }
};

export default transactionReducer;
