/**
 * 
 * 
 * 
 *     createUser,
    getUserByAddress,
    getUserCollectionByAdress,
    getUserCreatedNFTsByAddress,
    getUserOwnedNFTsByAddress
 */

import { call, put, takeEvery } from 'redux-saga/effects';
import { userApi } from '../../services/api';
import { userTypes } from '../types';

const createUser = function* (action) {
  try {
    const data = yield call(userApi.createUser, action.payload);
    yield put({ type: userTypes.CREATE_USER_SUCCESS, payload: data });
  } catch (error) {
    yield put({ type: userTypes.CREATE_USER_ERROR, payload: error });
  }
};
const getUserByAddress = function* (action) {
  try {
    const data = yield call(userApi.getUserByAddress, action.payload);
    yield put({ type: userTypes.GET_USER_SUCCESS, payload: data });
  } catch (error) {
    yield put({ type: userTypes.GET_USER_ERROR, payload: error });
  }
};
const getUserCollectionByAdress = function* (action) {
  try {
    const data = yield call(userApi.getUserCollectionByAddress, action.payload);
    yield put({ type: userTypes.GET_USER_COLLECTION_SUCCESS, payload: data });
  } catch (error) {
    yield put({ type: userTypes.GET_USER_COLLECTION_ERROR, payload: error });
  }
};
const getUserCollectionDetailedByAddress = function* (action) {
  try {
    const data = yield call(
      userApi.getUserCollectionDetailedByAddress,
      action.payload,
    );
    yield put({
      type: userTypes.GET_USER_COLLECTION_DETAILED_SUCCESS,
      payload: data,
    });
  } catch (error) {
    yield put({
      type: userTypes.GET_USER_COLLECTION_DETAILED_ERROR,
      payload: error,
    });
  }
};
const getUserCreatedNFTsByAddress = function* (action) {
  try {
    const data = yield call(
      userApi.getUserCreatedNFTsByAddress,
      action.payload,
    );
    yield put({ type: userTypes.GET_USER_CREATED_NFTS_SUCCESS, payload: data });
  } catch (error) {
    yield put({ type: userTypes.GET_USER_CREATED_NFTS_ERROR, payload: error });
  }
};
const getUserOwnedNFTsByAddress = function* (action) {
  try {
    const data = yield call(userApi.getUserOwnedNFTsByAddress, action.payload);
    yield put({ type: userTypes.GET_USER_OWNED_NFTS_SUCCESS, payload: data });
  } catch (error) {
    yield put({ type: userTypes.GET_USER_OWNED_NFTS_ERROR, payload: error });
  }
};
const getUserOnSaleNFTsByAddress = function* (action) {
  try {
    const data = yield call(userApi.getUserOnSaleNFTsByAddress, action.payload);
    yield put({ type: userTypes.GET_USER_ON_SALE_NFTS_SUCCESS, payload: data });
  } catch (error) {
    yield put({ type: userTypes.GET_USER_ON_SALE_NFTS_ERROR, payload: error });
  }
};
const getUserListingsByAddress = function* (action) {
  try {
    const data = yield call(userApi.getUserListingsByAddress, action.payload);
    yield put({ type: userTypes.GET_USER_LISTINGS_SUCCESS, payload: data });
  } catch (error) {
    yield put({ type: userTypes.GET_USER_LISTINGS_ERROR, payload: error });
  }
};

const userSaga = function* () {
  yield takeEvery(userTypes.CREATE_USER, createUser);
  yield takeEvery(userTypes.GET_USER, getUserByAddress);
  yield takeEvery(userTypes.GET_USER_COLLECTION, getUserCollectionByAdress);
  yield takeEvery(
    userTypes.GET_USER_COLLECTION_DETAILED,
    getUserCollectionDetailedByAddress,
  );
  yield takeEvery(userTypes.GET_USER_CREATED_NFTS, getUserCreatedNFTsByAddress);
  yield takeEvery(userTypes.GET_USER_OWNED_NFTS, getUserOwnedNFTsByAddress);
  yield takeEvery(userTypes.GET_USER_ON_SALE_NFTS, getUserOnSaleNFTsByAddress);
  yield takeEvery(userTypes.GET_USER_LISTINGS, getUserListingsByAddress);
};

export default userSaga;
