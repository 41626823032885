import { combineReducers } from 'redux';
import collectionReducer from './collections';
import bidsReducer from './bids';
import transactionReducer from './transactions';
import usersReducer from './users';

const reducer = combineReducers({
  collection: collectionReducer,
  bids: bidsReducer,
  transactions: transactionReducer,
  user: usersReducer,
});

export default reducer;
