import React from 'react';
import { useGlobalState } from '../../store';
import CreateNFT from './modals/CreateNFT';
import ConnectWallet from './modals/ConnectWallet';
import NFTCardSettingsPopup from './modals/NFTCardSettingsPopup';
import NFTCreatedSuccessfully from './modals/NFTCreatedSucessfullyModal';
import PutOnSale from './modals/PutOnSale';
import StorylinesPopup from './modals/StorylinesPopup';
import UnlimitedStorylinesPopup from './modals/UnlimitedStorilinesPopup';
import EditProfilePopup from './modals/EditProfilePopup';
import { CreateCollectionModal } from '../CreateCollectionModal/CreateCollectionModal';
import { BuyNowNftModal } from '../BuyNowNftModal/BuyNowNftModal';
import RemoveFromSale from './modals/RemoveFromSale';
import { SwapModal } from './modals/SwapModal';

const CustomModal = ({ platform }) => {
  const [boxModalType] = useGlobalState('boxModalType');
  const [modalPosition] = useGlobalState('modalPosition');
  const modalStyles = {
    position: 'absolute',
    top: modalPosition.top,
    left: modalPosition.left,
    transition: 'transform 0.3s ease',
  };

  const renderModallInner = () => {
    switch (boxModalType) {
      case 'createNft':
        return <CreateNFT />;
      case 'connectWallet':
        return <ConnectWallet platform={platform} />;
      case 'nftCardSettings':
        return (
          <div style={modalStyles}>
            <NFTCardSettingsPopup />
          </div>
        );
      case 'nftCreatedSuccessfully':
        return <NFTCreatedSuccessfully />;
      case 'putOnSale':
        return <PutOnSale />;
      case 'removeFromSale':
        return <RemoveFromSale />;
      case 'storylinesPopup':
        return <StorylinesPopup />;
      case 'unlimitedStorylinesPopup':
        return <UnlimitedStorylinesPopup />;
      case 'editProfilePopup':
        return <EditProfilePopup title={'Сoming soon...'} />;
      case 'moreNftPopup':
        return (
          <EditProfilePopup
            title={
              <div>
                More NFTs will be <br /> available soon
              </div>
            }
          />
        );
      case 'createCollection':
        return <CreateCollectionModal />;
      case 'buyNow':
        return <BuyNowNftModal />;
      case 'swapModal':
        return <SwapModal />;
      default:
        return <div />;
    }
  };

  return <>{renderModallInner()}</>;
};

export default CustomModal;
