import { setGlobalState, useGlobalState } from '@/store';

const EditProfilePopup = ({ title }) => {
  const [boxModal] = useGlobalState('boxModal');
  const closeModal = () => {
    setGlobalState('boxModal', 'scale-0');
    setGlobalState('boxModalType', null);
  };

  return (
    <div
      className={`fixed top-0 left-0 w-screen h-screen flex items-center
        justify-center bg-[#1D1F24CC] bg-opacity-30 transform
        transition-transform duration-300 z-50 ${boxModal}`}
    >
      <div className="flex flex-col items-center w-[460px] pt-[40px] p-[20px] border border-whiteBorder rounded-[20px] bg-[#0E0E0E]">
        <img
          className="w-[94px] h-[94px] mb-[75px]"
          src="/png/soonClock.png"
          alt=""
        />
        <span className="font-archivo text-white text-center font-semibold text-[30px] leading-[36px] mb-[75px]">
          {title}
        </span>
        <button
          onClick={closeModal}
          className="flex flex-col items-center w-[100%] py-[20px] px-[24px] text-white text-[16px] leading-[16px] shadow-primary border border-transparent font-spaceGrotesk bg-primary rounded-[18px] hover:bg-[#1d1f24]  hover:text-primary hover:border-1 hover:border-primary hover:shadow-none transition duration-300 ease-in-out"
        >
          Back
        </button>
      </div>
    </div>
  );
};

export default EditProfilePopup;
