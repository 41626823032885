import { call, put, takeEvery } from 'redux-saga/effects';
import { bidsApi } from '../../services/api';
import { bidsTypes } from '../types';

/**
 * 
 *     getBidsMadeByAddress,
    getBidsReceivedByAddress,
    getBidByAddressAndNFTId,
 */
const getBidsMadeByAddress = function* (action) {
  try {
    const data = yield call(bidsApi.getBidsMadeByAddress, action.payload);
    yield put({ type: bidsTypes.GET_BIDS_MADE_SUCCESS, payload: data });
  } catch (error) {
    yield put({ type: bidsTypes.GET_BIDS_MADE_ERROR, payload: error });
  }
};
const getBidsReceivedByAddress = function* (action) {
  try {
    const data = yield call(bidsApi.getBidsReceivedByAddress, action.payload);
    yield put({ type: bidsTypes.GET_BIDS_RECEIVED_SUCCESS, payload: data });
  } catch (error) {
    yield put({ type: bidsTypes.GET_BIDS_RECEIVED_ERROR, payload: error });
  }
};
const getBidByAddressAndNFTId = function* (action) {
  try {
    const data = yield call(bidsApi.getBidsByAddressAndNFTId, action.payload);
    yield put({ type: bidsTypes.GET_BIDS_SUCCESS, payload: data });
  } catch (error) {
    yield put({ type: bidsTypes.GET_BIDS_ERROR, payload: error });
  }
};
const bidsSaga = function* () {
  yield takeEvery(bidsTypes.GET_BIDS_MADE, getBidsMadeByAddress);
  yield takeEvery(bidsTypes.GET_BIDS_RECEIVED, getBidsReceivedByAddress);
  yield takeEvery(bidsTypes.GET_BIDS, getBidByAddressAndNFTId);
};

export default bidsSaga;
