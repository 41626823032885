import { Box, Modal } from '@mui/material';
import { getGlobalState, setGlobalState } from '@/store';
import { cancelAuction } from '@/services/Auction/auction-blockchain';
import toast from 'react-hot-toast';
import { useWeb3ModalProvider } from '@web3modal/ethers5/react';

const RemoveFromSale = () => {
  const cardData = getGlobalState('nftCardData');
  const { walletProvider } = useWeb3ModalProvider();

  const handleCloseModal = () => {
    setGlobalState('boxModalType', null);
  };

  const handleCancelAuction = async () => {
    try {
      toast.loading('Cancelling auction');

      await cancelAuction({
        collectionAddress: cardData?.collection_address,
        nftId: cardData?.token_id,
        walletProvider,
      });
      toast.dismiss();
      toast.success('Auction cancelled');
      window.location.reload();
    } catch (error) {
      toast.dismiss();
      toast.error('Error cancelling auction');
    }

    handleCloseModal();
  };

  return (
    <Modal open={true} onClose={handleCloseModal}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          border: `1px solid #605B59`,
          borderRadius: '20px',
          backgroundColor: 'rgba(29, 31, 36, 0.4)',
          padding: '30px',
          color: 'white',
          maxWidth: '476px',
          outline: 'none',
        }}
        className="backdrop-blur-[50px]"
      >
        <div className="flex flex-col gap-[20px] items-start">
          <div className="text-[30px] font-semibold">
            Do you want to remove your NFT from sale?
          </div>
          <div className="text-[16px]">You can put it on sale anytime</div>
          <div className="w-full ">
            <div
              className="h-[1px] w-full mt-[14px] mb-[14px]"
              style={{ backgroundColor: 'rgba(255, 255, 255, 0.2)' }}
            />
          </div>
          <button
            type="button"
            className="bg-white rounded-[18px] text-center py-[20px] grow font-spaceGrotesk font-semibold text-[16px] leading-[16px] text-[#0E0E0E] border-2 border-white hover:bg-[#0E0E0E] hover:border-white hover:text-white transition duration-300 ease-in-out w-full"
            onClick={handleCancelAuction}
          >
            Remove from sale
          </button>
          <button
            type="button"
            className="rounded-[18px] text-center py-[20px] grow font-spaceGrotesk font-semibold text-[16px] leading-[16px] border-2 border-white hover:bg-[#0E0E0E] hover:border-white hover:text-white transition duration-300 ease-in-out w-full"
            onClick={handleCloseModal}
          >
            Cancel
          </button>
        </div>
      </Box>
    </Modal>
  );
};

export default RemoveFromSale;
