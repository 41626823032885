import React, { useMemo, useState } from 'react';
import {
  getGlobalState,
  setGlobalState,
  truncate,
  useGlobalState,
} from '@/store';
import { FaTimes } from 'react-icons/fa';
import { expirationDates } from '@/data/data';
import SelectItem from '../../Select/SelectItem';
import CustomCalendar from '../../CustomCalendar';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import duration from 'dayjs/plugin/duration';
import toast from 'react-hot-toast';
import Tabs from '../../Tabs';
import { createAuction } from '@/services/Auction/auction-blockchain';
import {
  useWeb3Modal,
  useWeb3ModalAccount,
  useWeb3ModalProvider,
} from '@web3modal/ethers5/react';
dayjs.extend(localizedFormat);
dayjs.extend(duration);

const PutOnSale = () => {
  const cardData = getGlobalState('nftCardData');
  const [boxModal] = useGlobalState('boxModal');
  const [flag, setFlag] = useState(false);
  const [expirationDateValue, setExpirationDateValue] = useState(1);
  const [expirationValue, setExpirationValue] = useState(0);
  const [value, onChange] = useState(null);
  const [price, setPrice] = useState(cardData.price);
  const [startingBid, setStartingBid] = useState(0);
  const [biddable, setBiddable] = useState(true);
  const { walletProvider } = useWeb3ModalProvider();
  const { isConnected } = useWeb3ModalAccount();
  const { open } = useWeb3Modal();
  const now = new Date();
  const currentHour = String(now.getHours()).padStart(2, '0');
  const currentMinute = String(now.getMinutes()).padStart(2, '0');
  const currentDate = `${String(now.getDate()).padStart(2, '0')}.${String(
    now.getMonth() + 1,
  ).padStart(2, '0')}.${now.getFullYear()}`;

  const [chosenDate, setChosenDate] = useState({
    date: currentDate,
    hour: currentHour,
    minute: currentMinute,
    PMorAM: currentHour >= 12 ? 'PM' : 'AM',
  });

  const closeModal = () => {
    setGlobalState('boxModal', 'scale-0');
    setGlobalState('boxModalType', null);
  };

  const onSelect = (val, title) => {
    setExpirationValue(title);
    setExpirationDateValue(dayjs().add(val, 'day'));
    onChange(dayjs().add(val, 'day'));
    setFlag(!flag);
  };

  const diff = useMemo(() => {
    const givenDate = dayjs(value).format('L').split('/');
    const now = dayjs();

    const formattedDate = new Date(
      `${givenDate[2]}-${givenDate[0]}-${givenDate[1]}T${chosenDate.hour}:${chosenDate.minute}:00`,
    );

    const diffFromNowSec = dayjs(formattedDate).diff(now, 'second');

    const diff = dayjs.duration(now.diff(formattedDate));

    const days = Math.abs(Math.floor(diff.days()));
    const hours = Math.abs(diff.hours());
    const minutes = Math.abs(diff.minutes());
    const seconds = Math.abs(diff.seconds());

    return { days, hours, minutes, seconds, formattedDate, diffFromNowSec };
  }, [value, chosenDate]);

  const onSell = async (e) => {
    e.preventDefault();
    if (!isConnected) {
      open();
      return;
    }
    if (!price || expirationValue == 0) {
      return;
    }

    const params = {
      biddable: biddable,
    };

    params.duration = diff.diffFromNowSec;
    params.price = biddable ? startingBid : price;
    params.buyoutPrice = price;
    toast.loading(
      'It may take up to a couple minutes to process. Please wait...',
    );
    try {
      await createAuction({ ...cardData, ...params, walletProvider });
      toast.dismiss();
      toast.success('Item has been put on sale');
      window.location.reload();
    } catch (e) {
      toast.dismiss();
      toast.error('Error while putting item on sale');
    }
  };

  const royalty = cardData?.royalty;

  const earnValue = truncate(price - price * (royalty / 10000 + 0.05));

  return (
    <div
      className={`fixed top-0 left-0 w-screen h-screen bg-black bg-opacity-50 duration-300 ${boxModal} z-[1000] overflow-auto py-6`}
    >
      {expirationDateValue === 'Custom' ? (
        <CustomCalendar
          setChosenDate={setChosenDate}
          chosenDate={chosenDate}
          closeModal={closeModal}
          setExpirationDateValue={setExpirationDateValue}
          expirationDateValue={expirationDateValue}
          value={value}
          onChange={onChange}
        />
      ) : (
        <div className="flex flex-col justify-between h-fit w-[460px] p-[20px] items-center rounded-[20px] border border-whiteBorder backdrop-blur-[50px] bg-[#1D1F24]/opacity-40 mx-auto">
          <div
            className={`w-full flex flex-row justify-between items-center mb-[30px]`}
          >
            <div
              className={`flex w-[24px] h-[24px] justify-center cursor-pointer`}
            ></div>
            <div className={`flex justify-center cursor-pointer`}>
              <p className="font-archivo text-white font-medium text-[20px] leading-[30px]">
                Put on sale
              </p>
            </div>
            <div
              className={`flex w-[24px] h-[24px] justify-center cursor-pointer`}
              onClick={closeModal}
            >
              <FaTimes className="text-white w-[24px] h-[24px] items-center" />
            </div>
          </div>
          <div className="w-[420px] p-[14px] bg-[#1D1F24] rounded-[18px] justify-start items-center gap-3.5 inline-flex mb-[30px]">
            <div className="w-[72px] h-[72px] rounded-[12px] justify-center items-center flex">
              <img
                className="w-[72px] h-[72px] rounded-[12px] object-cover"
                src={cardData?.image}
                alt=""
              />
            </div>
            <div className="text-white text-2xl font-semibold font-['Archivo']">
              {cardData?.name}
            </div>
          </div>
          <div className="self-stretch flex-col justify-start items-start flex mb-[20px]">
            <div className="justify-start items-start inline-flex mb-[10px]">
              <div className="text-white text-base font-medium font-['Archivo'] leading-normal">
                Type of sale
              </div>
            </div>
            <div className="self-stretch">
              <Tabs
                tabs={[
                  {
                    title: 'Live auction',
                    value: true,
                  },
                  {
                    title: 'Fixed price with offers',
                    value: false,
                  },
                ]}
                value={biddable}
                onChange={(val) => {
                  setBiddable(val);
                }}
              />
            </div>
          </div>
          <div className="w-[420px] flex-col justify-start items-start flex mb-[30px]">
            <div className="justify-start items-start inline-flex mb-[10px]">
              <div className="text-white text-base font-medium font-['Archivo'] leading-normal ">
                Date of listing expiration
              </div>
            </div>
            <div
              className="self-stretch px-[20px] py-[16px] bg-[#1D1F24] rounded-[18px] justify-start items-center inline-flex cursor-pointer relative"
              onClick={() => setFlag(!flag)}
            >
              <div className="grow shrink basis-0 self-stretch text-white text-base font-normal font-['Archivo'] leading-normal">
                <span>
                  {expirationDateValue === 'Custom' ||
                  expirationDateValue == null
                    ? `${diff.days} d ${diff.hours} h ${diff.minutes} m ${diff.seconds} s`
                    : expirationValue}
                </span>
              </div>
              <div className="justify-start items-center gap-2.5 flex">
                <div className="opacity-50 text-right text-white text-base font-normal font-['Archivo'] leading-normal">
                  {dayjs(
                    diff.formattedDate != 'Invalid Date'
                      ? diff.formattedDate
                      : new Date(),
                  ).format('DD.MM.YYYY hh:mm A')}
                </div>
                <div className="w-[18px] h-[18px] relative">
                  <img src="/png/Down.svg" alt="" />
                </div>
              </div>
            </div>
            {flag && (
              <div
                style={{ backgroundColor: '#1d1e24ed', opacity: 1 }}
                className="w-[420px] h-[228px]   flex-col justify-start items-center inline-flex z-11 days-container rounded-[20px] border border-white-30 absolute top-[310px]"
              >
                <div
                  style={{ backgroundColor: '#1D1F2466', opacity: 1 }}
                  className="w-[420px] h-[235px] p-1 border-white flex-col justify-start items-center inline-flex z-10 days-container rounded-[20px] border border-white-30 backdrop-blur-xl absolute"
                >
                  {expirationDates.map((i) => (
                    <SelectItem
                      title={i.title}
                      value={i.value}
                      onClick={(val) => {
                        onSelect(val, i.title);
                      }}
                      key={i.title}
                    />
                  ))}
                  <SelectItem
                    title={'Custom'}
                    value={'Custom'}
                    onClick={() => {
                      setExpirationDateValue('Custom');
                      setFlag(!flag);
                    }}
                  />
                </div>
              </div>
            )}
          </div>
          <div className="w-[420px] flex-col justify-start items-start flex">
            {biddable && (
              <div className="self-stretch flex-col justify-start items-start flex mb-[20px]">
                <div className="justify-start items-start inline-flex mb-[10px]">
                  <div className="text-white text-base font-medium font-['Archivo'] leading-normal">
                    {'Starting  bid'}
                  </div>
                </div>
                <div className="self-stretch px-[20px] py-[16px] bg-[#1D1F24] rounded-[18px] border justify-start items-center inline-flex">
                  <div className="grow shrink basis-0 self-stretch text-white text-base font-normal font-['Archivo'] leading-normal">
                    <input
                      type="text"
                      placeholder="price"
                      className="focus:border-none h-full w-full bg-[#1d1f24] border-none focus:ring-0 p-[0px]"
                      pattern="[0-9]*"
                      value={startingBid}
                      onChange={(e) =>
                        /^\d*\.?\d*$/.test(e.target.value) &&
                        setStartingBid(e.target.value)
                      }
                    />
                  </div>
                  <div className="justify-center items-center gap-[6px] flex">
                    <img
                      src="/png/Networks Crypto white.png"
                      className="w-[20px] h-[20px] relative"
                      alt=""
                    />
                    <div className="w-[33px] text-white text-base font-medium font-['Archivo']">
                      FTN
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div className="self-stretch flex-col justify-start items-start flex mb-[20px]">
              <div className="justify-start items-start inline-flex mb-[10px]">
                <div className="text-white text-base font-medium font-['Archivo'] leading-normal">
                  {biddable ? 'Set Buyout Price' : 'Set price'}
                </div>
              </div>
              <div className="self-stretch px-[20px] py-[16px] bg-[#1D1F24] rounded-[18px] border justify-start items-center inline-flex">
                <div className="grow shrink basis-0 self-stretch text-white text-base font-normal font-['Archivo'] leading-normal">
                  <input
                    type="text"
                    placeholder="price"
                    className="focus:border-none h-full w-full bg-[#1d1f24] border-none focus:ring-0 p-[0px]"
                    pattern="[0-9]*"
                    value={price}
                    onChange={(e) =>
                      /^\d*\.?\d*$/.test(e.target.value) &&
                      setPrice(e.target.value)
                    }
                  />
                </div>
                <div className="justify-center items-center gap-[6px] flex">
                  <img
                    src="/png/Networks Crypto white.png"
                    className="w-[20px] h-[20px] relative"
                    alt=""
                  />
                  <div className="w-[33px] text-white text-base font-medium font-['Archivo']">
                    FTN
                  </div>
                </div>
              </div>
            </div>
            <div className="self-stretch rounded-2xl flex-col justify-center items-center flex">
              <div className="self-stretch justify-between items-start inline-flex mb-[14px]">
                <div className="text-white opacity-[50%] text-base font-normal font-['Archivo'] leading-normal">
                  You&apos;ll earn
                </div>
                <div className="justify-start items-center flex gap-[6px]">
                  <img
                    src="/png/Networks Crypto white.png"
                    className="w-[20px] h-[20px] relative"
                    alt=""
                  />
                  <div className="text-white text-base font-medium font-['Archivo'] truncate">
                    {earnValue} FTN
                  </div>
                </div>
              </div>
              <div className="self-stretch justify-between items-center inline-flex mb-[14px]">
                <div className="justify-start items-start flex">
                  <div className="text-white text-base font-normal font-['Archivo'] leading-none opacity-[50%]">
                    Marketplace Fee
                  </div>
                </div>
                <div className="text-white text-base font-normal font-['Archivo'] leading-none">
                  5 %
                </div>
              </div>
              <div className="self-stretch justify-between items-center inline-flex mb-[14px]">
                <div className="justify-start items-start flex">
                  <div className="text-white text-base font-normal font-['Archivo'] leading-none opacity-[50%]">
                    Royalty
                  </div>
                </div>
                <div className="text-white text-base font-normal font-['Archivo'] leading-none">
                  {cardData?.royalty / 100} %
                </div>
              </div>
              <div className="w-[100%] h-[2px] bg-[#FFFFFF1A] mb-[14px]"></div>
              <div className="self-stretch justify-between items-start inline-flex mb-[30px]">
                <div className="text-white text-base font-normal font-['Archivo'] leading-none opacity-[50%]">
                  Listing price
                </div>
                <div className="flex-col justify-start items-end gap-[5px] inline-flex">
                  <div className="justify-start items-center gap-[6px] inline-flex">
                    <img
                      src="/png/Networks Crypto white.png"
                      className="w-[30px] h-[30px] relative"
                      alt=""
                    />
                    <div className="text-white text-3xl font-medium font-['Archivo']">
                      {truncate(price?.toString(), 4, 4, 11)} FTN
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <button
            onClick={(e) => {
              onSell(e);
            }}
            className="bg-white rounded-[18px] text-center py-[20px] w-[100%] font-spaceGrotesk font-semibold text-[16px] leading-[16px] text-[#0E0E0E] border-2 border-white hover:bg-[#0E0E0E] hover:border-white hover:text-white transition duration-300 ease-in-out"
          >
            Sell
          </button>
        </div>
      )}
    </div>
  );
};

export default PutOnSale;
