import { call, put, takeEvery } from 'redux-saga/effects';
import { transactionApi } from '../../services/api';
import { transactionTypes } from '../types';

const getTransactionByAddressAndNFTId = function* (action) {
  try {
    const data = yield call(
      transactionApi.getTransactionByAddressAndNFTId,
      action.payload,
    );
    yield put({
      type: transactionTypes.GET_TRANSACTIONS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    yield put({
      type: transactionTypes.GET_TRANSACTIONS_ERROR,
      payload: error,
    });
  }
};

const transactionSaga = function* () {
  yield takeEvery(
    transactionTypes.GET_TRANSACTIONS,
    getTransactionByAddressAndNFTId,
  );
};
export default transactionSaga;
