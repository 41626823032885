import { useEffect, useState } from 'react';
import {
  setGlobalState,
  truncateAddress,
  truncateCustom,
  useGlobalState,
} from '@/store';
import axios from 'axios';

export const SwapModal = () => {
  const [modalScreen, setModalScreen] = useState('SWAP'); // SWAP | LOADING | WALLET | SUCCESS
  const [valueSend, setValueSend] = useState('');
  const [valueReceive, setValueReceive] = useState('');
  const [connectedAccount] = useGlobalState('connectedAccount');
  const [sendFieldActive, setSendFieldActive] = useState(false);
  const [receiveFieldActive, setReceiveFieldActive] = useState(false);
  const [selectActive, setSelectActive] = useState(false);
  const [currentNetwork, setCurrentNetwork] = useState('ERC20');
  const [rate, setRate] = useState(0);

  useEffect(() => {
    const getExchangeRate = async () => {
      const response = await axios.get(
        'https://api.coingecko.com/api/v3/simple/price?ids=tether,fasttoken&vs_currencies=usd',
      );

      const usdtToUsdRate = response.data.tether.usd;
      const ftnToUsdRate = response.data.fasttoken.usd;

      const newRate = usdtToUsdRate / ftnToUsdRate;

      setRate(newRate);
    };

    getExchangeRate();
  }, []);

  const getCurrentNetworkIcon = () => {
    switch (currentNetwork) {
      case 'ERC20':
        return '/svg/erc20.svg';
      case 'Polygon':
        return '/svg/polygon.svg';
      case 'TRC20':
        return '/svg/trc20.svg';
      case 'TON':
        return '/svg/ton.svg';
      default:
        return '/svg/ftn.svg';
    }
  };

  const toggleSelect = () => {
    setSelectActive((prev) => !prev);
  };

  const handleValueSendChange = (e) => {
    const { value } = e.target;

    setValueSend(value);
    setValueReceive(value * rate);
  };

  const handleValueReceiveChange = (e) => {
    const { value } = e.target;

    setValueReceive(value);
    setValueSend(value * rate);
  };

  const handleClose = () => {
    setGlobalState('boxModalType', '');
  };

  const handleSetNetwork = (network) => {
    setCurrentNetwork(network);
    toggleSelect();
  };

  return (
    <div className="fixed h-screen w-screen flex justify-center items-center top-0 left-0 z-[1000] text-white bg-[#0e0e0e99]">
      <div
        className="flex flex-col align-middle gap-[20px] border-white border-[1px] rounded-[20px] w-[460px] p-[20px] h-fit min-h-[560px]"
        style={{
          backdropFilter: 'blur(60px)',
        }}
        onClick={(e) => e.stopPropagation()}
      >
        {modalScreen === 'SWAP' && (
          <>
            <div className="w-full font-semibold text-center text-xl mb-[20px]">
              Swap / Exchange
            </div>
            <div className="flex flex-col gap-[10px]">
              <div className="font-medium text-base">Choose your wallet</div>
              <div
                className="bg-[#1D1F24] rounded-[18px] flex justify-between px-[20px] py-4 cursor-pointer"
                onClick={() => setModalScreen('WALLET')}
              >
                <div className="flex gap-[10px] items-center">
                  <img src="/svg/ftn.svg" alt="ftn" className="w-6 h-6" />
                  <span className="font-medium text-sm">
                    {truncateAddress(connectedAccount, 10, 4, 11)}
                  </span>
                </div>
                <img
                  src="/svg/arrowRight.svg"
                  alt="wallet"
                  className="rotate"
                />
              </div>
            </div>
            <div
              className={`bg-[#1D1F24] rounded-[18px] flex flex-col border-[1px] gap-[24px] p-4 transition-all ${
                sendFieldActive ? 'border-white' : 'border-[#1D1F24] relative'
              }`}
            >
              <span className="font-semibold text-base">Send</span>
              <div className="flex justify-between items-center">
                <input
                  className="font-bold opacity-1 text-[30px] max-w-[230px] overflow-hidden text-ellipsis bg-transparent focus:border-0 border-0 placeholder:opacity-50 p-0"
                  value={valueSend}
                  onChange={handleValueSendChange}
                  placeholder={0}
                  onFocus={() => setSendFieldActive(true)}
                  onBlur={() => setSendFieldActive(false)}
                  type="number"
                />
                <div
                  className="flex cursor-pointer gap-[10px]"
                  onClick={toggleSelect}
                >
                  <div className="flex gap-[6px]">
                    <img src={getCurrentNetworkIcon()} alt="network" />
                    <img src="/svg/usdt.svg" alt="network" />
                  </div>
                  <span>{currentNetwork}</span>
                  <img
                    src="/svg/arrowRight.svg"
                    alt="arrow"
                    className={
                      selectActive ? 'rotate-[270deg]' : 'rotate-[90deg]'
                    }
                  />
                </div>
                {selectActive && (
                  <div
                    className="absolute top-[110%] p-1 w-full left-0 border border-[#ffffff2d] rounded-[16px]"
                    style={{
                      backdropFilter: 'blur(20px)',
                      backgroundColor: '#1d1f24f0',
                    }}
                  >
                    <div
                      className="py-[12px] px-4 flex gap-3 cursor-pointer hover:bg-[#ffffff17] rounded-[12px]"
                      onClick={() => handleSetNetwork('Polygon')}
                    >
                      <div className="flex gap-[6px]">
                        <img src="/svg/polygon.svg" alt="network" />
                        <img src="/svg/usdt.svg" alt="network" />
                      </div>
                      <span>USDT (Polygon)</span>
                    </div>
                    <div
                      className="py-[12px] px-4 flex gap-3 cursor-pointer hover:bg-[#ffffff17] rounded-[12px]"
                      onClick={() => handleSetNetwork('ERC20')}
                    >
                      <div className="flex gap-[6px]">
                        <img src="/svg/erc20.svg" alt="network" />
                        <img src="/svg/usdt.svg" alt="network" />
                      </div>
                      <span>USDT (ERC20)</span>
                    </div>
                    <div
                      className="py-[12px] px-4 flex gap-3 cursor-pointer hover:bg-[#ffffff17] rounded-[12px]"
                      onClick={() => handleSetNetwork('TRC20')}
                    >
                      <div className="flex gap-[6px]">
                        <img src="/svg/trc20.svg" alt="network" />
                        <img src="/svg/usdt.svg" alt="network" />
                      </div>
                      <span>USDT (TRC20)</span>
                    </div>
                    <div
                      className="py-[12px] px-4 flex gap-3 cursor-pointer hover:bg-[#ffffff17] rounded-[12px]"
                      onClick={() => handleSetNetwork('TON')}
                    >
                      <div className="flex gap-[6px]">
                        <img src="/svg/ton.svg" alt="network" />
                        <img src="/svg/usdt.svg" alt="network" />
                      </div>
                      <span>USDT (TON)</span>
                    </div>
                    <div
                      className="py-[12px] px-4 flex gap-3 cursor-pointer hover:bg-[#ffffff17] rounded-[12px]"
                      onClick={() => handleSetNetwork('FTN')}
                    >
                      <div className="flex gap-[6px]">
                        <img src="/svg/ftn.svg" alt="network" />
                      </div>
                      <span>FTN (Bahamut)</span>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div
              className={`bg-[#1D1F24] rounded-[18px] flex flex-col border-[1px] gap-[24px] p-4 transition-all ${
                receiveFieldActive ? 'border-white' : 'border-[#1D1F24]'
              }`}
            >
              <span className="font-semibold text-base">Receive</span>
              <div className="flex justify-between items-center">
                <input
                  className="font-bold opacity-1 text-[30px] max-w-[300px] bg-transparent focus:border-0 border-0 placeholder:opacity-50 p-0 overflow-hidden text-ellipsis"
                  value={valueReceive}
                  onChange={handleValueReceiveChange}
                  placeholder={0}
                  onFocus={() => setReceiveFieldActive(true)}
                  onBlur={() => setReceiveFieldActive(false)}
                  type="number"
                />
                <div className="flex gap-[10px]">
                  <img src="/svg/ftn.svg" alt="ftn" className="w-6 h-6" />
                  <span className="font-semibold text-base">FTN</span>
                </div>
              </div>
            </div>
            <button className="w-full py-[20px] px-[24px] flex flex-col items-center bg-white rounded-[18px] text-black  font-spaceGrotesk text-[16px] leading-[16px] font-semiBold hover:bg-black_button hover:text-white border transition duration-300 ease-in-out">
              Confirm
            </button>
            <img
              src="/svg/closeIcon.svg"
              className="w-[24px] h-[24px] absolute top-[23px] right-[20px] cursor-pointer"
              alt="close"
              onClick={handleClose}
            />
          </>
        )}

        {modalScreen === 'WALLET' && (
          <>
            <div className="w-full font-semibold text-center text-xl mb-[20px]">
              Choose your wallet
            </div>
            <img
              src="/svg/arrowLeft.svg"
              className="w-[24px] h-[24px] absolute top-[23px] left-[20px] cursor-pointer"
              alt="close"
              onClick={() => setModalScreen('SWAP')}
            />
            <img
              src="/svg/closeIcon.svg"
              className="w-[24px] h-[24px] absolute top-[23px] right-[20px] cursor-pointer"
              alt="close"
              onClick={handleClose}
            />
            <div className="flex flex-col gap-[10px]">
              <div className="font-medium text-base">My wallets</div>
              <div className="flex gap-3 items-center border border-[#FFFFFF4D] bg-[#0E0E0E] p-4 rounded-[16px]">
                <img
                  src="/svg/ftn.svg"
                  alt="ftn"
                  className="w-[46px] h-[46px]"
                />
                <div className="flex flex-col gap-1">
                  <div className="mt-1">
                    <span>{truncateCustom(connectedAccount, 16, 4, 20)}</span>
                  </div>
                  <span>Bahamut</span>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};
