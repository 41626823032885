const GET_BIDS_MADE = '@bidsTypes/GET_BIDS_MADE';
const GET_BIDS_MADE_SUCCESS = '@bidsTypes/GET_BIDS_MADE_SUCCESS';
const GET_BIDS_MADE_ERROR = '@bidsTypes/GET_BIDS_MADE_ERROR';
const GET_BIDS_RECEIVED = '@bidsTypes/GET_BIDS_RECEIVED';
const GET_BIDS_RECEIVED_SUCCESS = '@bidsTypes/GET_BIDS_RECEIVED_SUCCESS';
const GET_BIDS_RECEIVED_ERROR = '@bidsTypes/GET_BIDS_RECEIVED_ERROR';
const GET_BIDS = '@bidsTypes/GET_BIDS';
const GET_BIDS_SUCCESS = '@bidsTypes/GET_BIDS_SUCCESS';
const GET_BIDS_ERROR = '@bidsTypes/GET_BIDS_ERROR';
export {
  GET_BIDS_MADE,
  GET_BIDS_MADE_SUCCESS,
  GET_BIDS_MADE_ERROR,
  GET_BIDS_RECEIVED,
  GET_BIDS_RECEIVED_SUCCESS,
  GET_BIDS_RECEIVED_ERROR,
  GET_BIDS,
  GET_BIDS_SUCCESS,
  GET_BIDS_ERROR,
};
