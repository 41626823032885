import React, { useState } from 'react';
import { setGlobalState, useGlobalState } from '@/store';
import HeaderInput from './HeaderInput';
import HeaderUserAvatar from './HeaderUserAvatar';
import { useWeb3Modal } from '@web3modal/ethers5/react';
import { useRouter } from 'next/router';
import Link from 'next/link';

export const Header = () => {
  const [connectedAccount] = useGlobalState('connectedAccount');
  // eslint-disable-next-line no-unused-vars
  const [headerInput, setHeaderInput] = useState(false);
  const { pathname } = useRouter();
  const router = useRouter();
  const { open } = useWeb3Modal();

  return (
    <header className="w-[100%] py-[22px] sticky top-0 z-30  border-whiteBorder backdrop-blur-[50px]">
      <div className="main_container flex flex-row justify-between items-center">
        <div className="flex flex-row items-center gap-[50px] ">
          <Link href="/" className="w-[50px] h-[25px]">
            <img src="/png/logo.png" alt="" />
          </Link>
          {headerInput && <HeaderInput />}
          <nav className="flex flex-row gap-[30px]">
            <Link
              href="/"
              className="cursor-pointer text-[16px] leading-[16px] font-archivo font-semiBold"
              style={
                pathname === '/'
                  ? { color: `rgba(255,255,255,1)` }
                  : { color: `rgba(255,255,255,0.3)` }
              }
            >
              Home
            </Link>
            <Link
              href="/explore"
              className="cursor-pointer text-[16px] leading-[16px] font-archivo font-semiBold"
              style={
                pathname === '/explore'
                  ? { color: `rgba(255,255,255,1)` }
                  : { color: `rgba(255,255,255,0.3)` }
              }
            >
              Explore
            </Link>
            <Link
              onClick={(event) => {
                event.preventDefault();
                event.stopPropagation();
                if (connectedAccount) {
                  router.push('/create-nft');
                } else {
                  open();
                }

                setGlobalState('boxModal', 'scale-100');
              }}
              href="/create-nft"
              className="cursor-pointer text-[16px] leading-[16px] font-archivo font-semiBold"
              style={
                pathname === '/create-nft'
                  ? { color: `rgba(255,255,255,1)` }
                  : { color: `rgba(255,255,255,0.3)` }
              }
            >
              Create NFT
            </Link>
          </nav>
        </div>
        <div className="flex flex-row gap-[12px] items-center">
          <button
            onClick={() => {
              setGlobalState('boxModalType', 'unlimitedStorylinesPopup');
              setGlobalState('boxModal', 'scale-100');
            }}
            className="flex flex-col items-center py-[20px] px-[24px] text-white text-[16px] leading-[16px] shadow-primarybutton border border-transparent font-spaceGrotesk bg-primary rounded-[18px] hover:bg-[#1d1f24]  hover:text-primary hover:border-1 hover:border-primary hover:shadow-none transition duration-300 ease-in-out"
          >
            <p className="">Get unlimited storylines</p>
          </button>
          {connectedAccount ? (
            <HeaderUserAvatar />
          ) : (
            <div
              className="p-[16px] bg-dark_gray rounded-[18px] flex flex-col items-center cursor-pointer"
              onClick={() => {
                setGlobalState('redirect', null);
                if (window?.ethereum) {
                  open();
                }
              }}
            >
              <img
                src="/svg/Profile.svg"
                className="w-[24px] h-[24px]"
                alt=""
              />
            </div>
          )}
        </div>
      </div>
    </header>
  );
};
