import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useRouter } from 'next/router';
import {
  getGlobalState,
  setGlobalState,
  truncate,
  useGlobalState,
} from '@/store';
import { useDisconnect } from '@web3modal/ethers5/react';
import Link from 'next/link';

const HeaderUserAvatar = () => {
  const router = useRouter();
  const [userCard, setUserCard] = useState(false);
  const [balance] = useGlobalState('balance');
  const [connectedAccount] = useGlobalState('connectedAccount');
  const containerRef = useRef(null);
  const provider = getGlobalState('provider');
  const { disconnect } = useDisconnect();

  const handleDisconnect = useCallback(async () => {
    try {
      disconnect();
      router.push('/');
      setGlobalState('connectedAccount', null);
    } catch (e) {
      return e;
    }
  }, [provider]);

  const handleClickOutside = (event) => {
    if (containerRef.current && !containerRef.current.contains(event.target)) {
      setUserCard(false);
    }
  };

  const renderBalance = (balance) => {
    const tmp = String(balance);
    let renderedBalance;
    if (tmp?.length > 10) {
      renderedBalance = tmp.slice(0, 10);
      return renderedBalance.concat('...');
    } else {
      return tmp;
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="flex flex-col relative gap-[10px]">
      <div
        className="px-[12px] py-[10px] cursor-pointer flex flex-row items-center gap-[8px] bg-dark_gray rounded-[18px]"
        onClick={() => {
          setUserCard(!userCard);
        }}
      >
        <img src="/svg/NFT_wb.svg" alt="" />
        <p className="w-[75px] max-w-[75px] text-white text-[14px] leading-[14px] font-archivo font-medium truncate">
          {truncate(connectedAccount, 4, 4, 11)}
        </p>
      </div>
      {userCard && (
        <div
          className="absolute right-0 top-[66px] pt-[6px] w-[272px] flex flex-col rounded-[20px] z-40 overflow-hidden"
          style={{
            backgroundColor: 'rgba(29, 31, 36, 0.7)',
            backgroundSize: 'cover',
          }}
          ref={containerRef}
        >
          <div className="backdrop-blur-xl px-[6px] pb-[6px] gap-[6px] ">
            <div className="flex flex-col w-full p-[12px] gap-[15px] rounded-[14px] bg-page_bg">
              <div className="flex flex-row w-full justify-between items-center">
                <p className="text-whiteBorder text-[14px] leading-[14px] font-archivo font-regular">
                  Balance
                </p>
                <div className="flex flex-row py-[6px] px-[8px] gap-[4px] rounded-[32px] bg-dark_gray items-center">
                  <p className="text-white text-[14px] leading-[14px] font-archivo font-regular">
                    {truncate(connectedAccount, 4, 4, 11)}
                  </p>
                  <div
                    style={{
                      width: '7px',
                      height: '7px',
                      borderRadius: '50%',
                      backgroundColor: '#82FF9D',
                    }}
                  />
                </div>
              </div>
              <div className="flex flex-row gap-[8px]">
                <img
                  src="/svg/cryptoWhite.svg"
                  className="w-[18px] h-[18px]"
                  alt=""
                />
                <p className="text-white text-[16px] leading-[16px] font-archivo font-semiBold truncate">
                  {renderBalance(balance)}
                </p>
                <p className="text-white text-[16px] leading-[16px] font-archivo font-semiBold ml-auto">
                  FTN
                </p>
              </div>
            </div>
            <div className="flex flex-col w-full mt-[6px]">
              <Link
                className="group flex flex-row p-[10px] gap-[8px] items-center cursor-pointer"
                onClick={() => {
                  setUserCard(false);
                }}
                href="/profile"
              >
                <div className="w-[18px] h-[18px] flex flex-col justify-center items-center ">
                  <img
                    src="/svg/myProfile.svg"
                    className="w-[13.5px] h-[13.5px] opacity-50 group-hover:opacity-100"
                    alt=""
                  />
                </div>
                <p className="text-white text-[16px] opacity-50 leading-[16px] font-archivo font-regular group-hover:opacity-100">
                  My profile
                </p>
              </Link>
              <div
                className="group flex flex-row p-[10px] gap-[8px] items-center cursor-pointer"
                onClick={handleDisconnect}
              >
                <div className="w-[18px] h-[18px] flex flex-col justify-center items-center ">
                  <img
                    src="/svg/link.svg"
                    className="w-[13.5px] h-[13.5px] opacity-50 group-hover:opacity-100"
                    alt=""
                  />
                </div>
                <p className="text-white text-[16px] leading-[16px] font-archivo font-regular opacity-50 group-hover:opacity-100">
                  Disconnect
                </p>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default HeaderUserAvatar;
