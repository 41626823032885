import React from 'react';

export default function SelectItem({ title, value, onClick }) {
  return (
    <div
      className="self-stretch h-[60px] px-[16px] py-[12px] justify-start items-center gap-1.5 inline-flex cursor-pointer hover:bg-[#323438] hover:backdrop-blur rounded-xl"
      onClick={() => onClick(value)}
    >
      <div className="text-white text-sm font-semibold font-['Archivo'] leading-[21px]">
        {title}
      </div>
    </div>
  );
}
