import { setGlobalState, useGlobalState } from '@/store';

const UnlimitedStorylinesPopup = () => {
  const [boxModal] = useGlobalState('boxModal');
  const closeModal = () => {
    setGlobalState('boxModal', 'scale-0');
    setGlobalState('boxModalType', null);
  };

  return (
    <div
      className={`fixed top-0 left-0 w-screen h-screen flex items-center
        justify-center bg-[#0E0E0E] bg-opacity-30 transform
        transition-transform duration-300 z-50 ${boxModal}`}
    >
      <div className="flex flex-col items-center w-[460px] p-[20px] border border-whiteBorder rounded-[20px] bg-[#0E0E0E]">
        <div className="flex flex-row justify-end w-[100%] mb-[86px]">
          <img
            className="cursor-pointer"
            onClick={closeModal}
            src="/svg/closeIcon.svg"
            alt="close"
          />
        </div>
        <span className="font-archivo text-white text-center font-semibold text-[30px] leading-[36px] mb-[20px]">
          Get unlimited storylines
        </span>
        <span className="font-archivo text-white text-center font-normal text-[16px] leading-[20.8px] mb-[86px]">
          View the stories in our Telegram chatbot. <br /> This will redirect
          you to an external service
        </span>
        <a
          className="w-[100%]"
          href="https://t.me/llaigame2024bot"
          target="_blank"
          rel="noreferrer"
        >
          <button className="flex flex-col items-center w-[100%] py-[20px] px-[24px] text-white text-[16px] leading-[16px] shadow-primary border border-transparent font-spaceGrotesk bg-primary rounded-[18px] hover:bg-[#1d1f24]  hover:text-primary hover:border-1 hover:border-primary hover:shadow-none transition duration-300 ease-in-out">
            Go to ChatBot
          </button>
        </a>
      </div>
    </div>
  );
};

export default UnlimitedStorylinesPopup;
