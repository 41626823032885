import { Box, Modal } from '@mui/material';
import { setGlobalState } from '@/store';
import { useForm } from 'react-hook-form';
import { useState } from 'react';
import { createNewCollection } from '@/services/NFTFabric/nft-fabrick-blockchain';
import axios from 'axios';
import toast from 'react-hot-toast';
import {
  useWeb3Modal,
  useWeb3ModalAccount,
  useWeb3ModalProvider,
} from '@web3modal/ethers5/react';

const BACKEND_ADDRESS = process.env.BACKEND_ADDRESS;

export const CreateCollectionModal = () => {
  const handleCloseModal = () => {
    setGlobalState('boxModalType', null);
  };
  const [charCount, setCharCount] = useState(0);
  const [avatarImageURL, setAvatarImageURL] = useState(null);
  const [avatarImage, setAvatarImage] = useState(null);
  const [bannerImageURL, setBannerImageURL] = useState(null);
  const [bannerImage, setBannerImage] = useState(null);
  const { register, handleSubmit } = useForm();
  const { walletProvider } = useWeb3ModalProvider();
  const web3Acc = useWeb3ModalAccount();
  const web3modalInstance = useWeb3Modal();

  const deleteAvatarImage = () => {
    URL.revokeObjectURL(avatarImageURL);
    setAvatarImage(null);
    setAvatarImageURL(null);
  };
  const deleteBannerImage = () => {
    URL.revokeObjectURL(bannerImage);
    setBannerImage(null);
    setBannerImageURL(null);
  };

  const onSubmit = (values) => {
    if (web3Acc.chainId !== Number(process.env.CHAIN_ID)) {
      web3modalInstance.open({
        view: 'Networks',
      });
      return;
    }
    const formData = new FormData();
    formData.append('avatar', avatarImage);
    formData.append('banner', bannerImage);
    formData.append('name', values.name);
    formData.append('symbol', values.symbol);
    formData.append('description', values.description);
    toast.loading(`Processing...`);

    const postData = async () => {
      let response;

      try {
        response = await axios.post(
          `${BACKEND_ADDRESS}upload_collection_to_ipfs`,
          formData,
        );
        toast.dismiss();
      } catch (error) {
        toast.error('Failed to upload collection to IPFS');
      }
      if (!response) {
        toast.error('Failed to create collection');
        return;
      }

      const data = response.data;
      const ipfsHash = data.split('ipfs/')[1];

      if (!ipfsHash) {
        return;
      }

      try {
        toast.loading('Creating collection...');
        await createNewCollection({
          name: values.name,
          symbol: values.symbol,
          uri: ipfsHash,
          walletProvider,
        });
        setGlobalState('collectionWasCreatedInPage', true);
        toast.success('Collection created successfully');
      } catch (error) {
        toast.error('Failed to create collection');
      } finally {
        toast.dismiss();
        setGlobalState('boxModalType', null);
        setGlobalState('boxModal', 'scale-0');
      }
    };

    postData();
  };

  const handleInputChange = (event) => {
    const inputText = event.target.value;
    if (inputText?.length <= 1000) {
      setCharCount(inputText?.length);
    }
  };

  const handleFileInput = async (e, isBanner) => {
    const file = e.target.files[0];
    const imageUrl = URL.createObjectURL(file);
    if (isBanner) {
      setBannerImage(file);
      setBannerImageURL(imageUrl);
    } else {
      setAvatarImage(file);
      setAvatarImageURL(imageUrl);
    }
    e.target.value = '';
  };

  return (
    <Modal open onClose={handleCloseModal}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          border: `1px solid #605B59`,
          borderRadius: '20px',
          backgroundColor: 'rgba(29, 31, 36, 0.4)',
          padding: '30px',
          color: 'white',
          maxWidth: '654.47px',
          width: '654.47px',
          outline: 'none',
          maxHeight: '90vh',
          overflow: 'auto',
          '&::-webkit-scrollbar': {
            width: '8px',
          },
          '&::-webkit-scrollbar-track': {
            borderRadius: '8px',
            backgroundColor: 'transparent',
            border: '6px solid transparent',
          },

          '&::-webkit-scrollbar-track:hover': {
            backgroundColor: 'transparent',
          },

          '&::-webkit-scrollbar-track:active': {
            backgroundColor: 'transparent',
          },

          '&::-webkit-scrollbar-thumb': {
            borderRadius: '8px',
            backgroundColor: 'transparent',
          },
        }}
        className="backdrop-blur-[50px]"
      >
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="flex flex-col gap-[30px] items-start"
        >
          <div className="text-[30px] font-semibold">Create collection</div>
          <div>
            <label className="text-white font-archivo font-medium text-[16px] leading-[24px] ">
              Upload collection avatar
              <span className="text-[#FE7762] font-archivo font-medium text-[23.66px] leading-[23.66px]">
                *
              </span>
            </label>
            <div className="flex gap-[20px] mt-[10px]">
              <img
                src={avatarImageURL || '/svg/emptyImage.svg'}
                className="object-cover w-[100px] h-[100px] rounded-[26px]"
                alt=""
              />
              <div className="flex flex-col justify-between">
                <div className="opacity-50 font-archivo text-white font-regular text-[14px] leading-[18.2px]">
                  At least 300x300 pixels, max. <br /> size 5MB, GIF, JPEG or
                  PNG
                </div>
                <div className="flex justify-between gap-[10px]">
                  <label
                    htmlFor="avatar_input"
                    className={`bg-white text-center rounded-[12px] py-[16px] px-[12px] text-black font-archivo font-medium text-[16px] leading-[16px] w-${
                      avatarImageURL ? 'full' : '6/12'
                    } hover:cursor-pointer`}
                  >
                    Choose file
                  </label>
                  <input
                    id="avatar_input"
                    multiple={false}
                    type="file"
                    accept=".jpg, .jpeg, .png, .gif"
                    className="hidden"
                    onChange={(e) => handleFileInput(e)}
                  />
                  {avatarImageURL && (
                    <button
                      className="text-[#FE7762] rounded-[18px] text-center py-[16px] px-[38px] font-spaceGrotesk font-semibold text-[16px] leading-[16px] bg-[#0E0E0E] hover:border-white hover:text-white transition duration-300 ease-in-out"
                      onClick={deleteAvatarImage}
                    >
                      Delete
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col w-full">
            <label className="text-white font-archivo font-medium text-[16px] leading-[24px] mb-[10px]">
              Name your Collection{' '}
              <span className="text-[#FE7762] font-archivo font-medium text-[23.66px] leading-[23.66px]">
                *
              </span>
            </label>
            <input
              {...register('name', { required: true })}
              className="px-[16px] py-[20px] rounded-[18px] bg-[#1D1F24] border border-[#1D1F24] text-white opacity-[100%] font-archivo font-regular text-[16px] leading-[24px] focus:border-[#1D1F24]"
              placeholder="Example: 8 Legends Arts"
            />
          </div>
          <div className="flex flex-col mb-[10px] w-full">
            <label className="text-white font-archivo font-medium text-[16px] leading-[24px] mb-[10px]">
              Token symbol
              <span className="text-[#FE7762] font-archivo font-medium text-[23.66px] leading-[23.66px]">
                *
              </span>
            </label>
            <input
              {...register('symbol', { required: true })}
              className="px-[16px] py-[20px] rounded-[18px] bg-[#1D1F24] border border-[#1D1F24] text-white opacity-[100%] font-archivo font-regular text-[16px] leading-[24px] focus:border-[#1D1F24]"
              placeholder="Example: LLAI"
            />
          </div>
          <div className="flex flex-col w-full">
            <label className="text-white font-archivo font-medium text-[16px] leading-[24px] mb-[10px]">
              Add description to your Collection{' '}
              <span className="text-[#FE7762] font-archivo font-medium text-[23.66px] leading-[23.66px]">
                *
              </span>
            </label>
            <textarea
              {...register('description', { required: true })}
              max={1000}
              maxLength={1000}
              className={`px-[16px] py-[20px] rounded-[18px] bg-[#1D1F24] text-white opacity-[100%] font-archivo font-regular text-[16px] leading-[24px] h-[120px] mb-[10px] relative desc-input resize-none`}
              placeholder="Spread some words about your Collection"
              onChange={handleInputChange}
            />
            <span className="text-white opacity-[50%] font-archivo font-regular text-[16px] leading-[24px]">
              {charCount}/1000
            </span>
          </div>
          <div className="w-full">
            <div className="flex items-center justify-between">
              <label className="text-white font-archivo font-medium text-[16px] leading-[24px]">
                Upload banner image{' '}
                <span className="text-[#FE7762] font-archivo font-medium text-[23.66px] leading-[23.66px]">
                  *
                </span>
              </label>
              <div className="flex gap-[14px]">
                <label
                  htmlFor="banner_input"
                  className={`bg-white text-center rounded-[12px] py-[16px] px-[12px] text-black font-archivo font-medium text-[16px] leading-[16px] w-${
                    bannerImage ? 'full' : '6/12'
                  } hover:cursor-pointer`}
                >
                  Choose file
                </label>
                <input
                  id="banner_input"
                  multiple={false}
                  type="file"
                  accept=".jpg, .jpeg, .png, .gif"
                  className="hidden"
                  onChange={(e) => handleFileInput(e, true)}
                  value=""
                />
                <div className="">
                  {bannerImageURL && (
                    <button
                      className="text-[#FE7762] rounded-[18px] text-center py-[16px] px-[38px] grow font-spaceGrotesk font-semibold text-[16px] leading-[16px] bg-[#0E0E0E] hover:border-white hover:text-white transition duration-300 ease-in-out w-full"
                      onClick={deleteBannerImage}
                    >
                      Delete
                    </button>
                  )}
                </div>
              </div>
            </div>
            <div className="w-full mt-[20px] bg-[#0E0E0E] rounded-[18px]">
              {bannerImageURL ? (
                <img
                  src={bannerImageURL}
                  className="object-cover w-full h-[167px] rounded-[18px]"
                  alt=""
                />
              ) : (
                <div className="flex items-center justify-center border-2 border-dashed border-[#404040] w-full h-[167px] rounded-[18px]">
                  <div className="text-white opacity-[50%] font-archivo font-regular text-[14px] leading-[18.2px] text-center">
                    Supported file types and size limit
                    <ul className="file-formats__list">
                      <li>Image: JPG, JPEG, PNG</li>
                      <li>Size limit: up to 10 MB</li>
                    </ul>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="flex w-full gap-[14px]">
            <button
              type="button"
              className="rounded-[18px] text-center py-[20px] grow font-spaceGrotesk font-semibold text-[16px] leading-[16px] border-2 border-white hover:bg-[#0E0E0E] hover:border-white hover:text-white transition duration-300 ease-in-out w-full"
              onClick={handleCloseModal}
            >
              Cancel
            </button>
            <button
              className="bg-white rounded-[18px] text-center py-[20px] grow font-spaceGrotesk font-semibold text-[16px] leading-[16px] text-[#0E0E0E] border-2 border-white hover:bg-[#0E0E0E] hover:border-white hover:text-white transition duration-300 ease-in-out w-full"
              type="submit"
            >
              Apply
            </button>
          </div>
        </form>
      </Box>
    </Modal>
  );
};
