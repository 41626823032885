const GET_COLLECTIONS = '@collectionActions/GET_COLLECTIONS';
const GET_COLLECTIONS_SUCCESS = '@collectionActions/GET_COLLECTIONS_SUCCESS';
const GET_COLLECTIONS_ERROR = '@collectionActions/GET_COLLECTIONS_ERROR';
const CREATE_COLLECTION_SUCCESS =
  '@collectionActions/CREATE_COLLECTION_SUCCESS';
const CREATE_COLLECTION_ERROR = '@collectionActions/CREATE_COLLECTION_ERROR';
const GET_SINGLE_COLLECTION_ERROR =
  '@collectionActions/GET_SINGLE_COLLECTION_ERROR';
const GET_SINGLE_COLLECTION_SUCCESS =
  '@collectionActions/GET_SINGLE_COLLECTION_SUCCESS';
const GET_COLLECTIONS_STATISTICS_SUCCESS =
  '@collectionActions/GET_COLLECTIONS_STATISTICS_SUCCESS';
const GET_COLLECTIONS_STATISTICS_ERROR =
  '@collectionActions/GET_COLLECTIONS_STATISTICS_ERROR';
const GET_COLLECTION_NFTS_SUCCESS =
  '@collectionActions/GET_COLLECTION_NFTS_SUCCESS';
const GET_COLLECTION_NFTS_ERROR =
  '@collectionActions/GET_COLLECTION_NFTS_ERROR';
const GET_COLLECTION_NFT_SUCCESS =
  '@collectionActions/GET_COLLECTION_NFT_SUCCESS';
const GET_COLLECTION_NFT_ERROR = '@collectionActions/GET_COLLECTION_NFT_ERROR';
const CREATE_COLLECTION = '@collectionActions/CREATE_COLLECTION';
const GET_SINGLE_COLLECTION = '@collectionActions/GET_SINGLE_COLLECTION';
const GET_COLLECTIONS_STATISTICS =
  '@collectionActions/GET_COLLECTIONS_STATISTICS';
const GET_COLLECTION_NFTS = '@collectionActions/GET_COLLECTION_NFTS';
const GET_COLLECTION_NFT = '@collectionActions/GET_COLLECTION_NFT';
const GET_TOP_5_COLLECTIONS = '@collectionActions/GET_TOP_5_COLLECTIONS';
const GET_TOP_5_COLLECTIONS_SUCCESS =
  '@collectionActions/GET_TOP_5_COLLECTIONS_SUCCESS';
const GET_TOP_5_COLLECTIONS_ERROR =
  '@collectionActions/GET_TOP_5_COLLECTIONS_ERROR';
const GET_COLLECTION_LIST = '@collectionActions/GET_COLLECTION_LIST';
const GET_COLLECTION_LIST_SUCCESS =
  '@collectionActions/GET_COLLECTION_LIST_SUCCESS';
const GET_COLLECTION_LIST_ERROR =
  '@collectionActions/GET_COLLECTION_LIST_ERROR';
const GET_NFT_LIST = '@collectionActions/GET_NFT_LIST';
const GET_NFT_LIST_SUCCESS = '@collectionActions/GET_NFT_LIST_SUCCESS';
const GET_NFT_LIST_ERROR = '@collectionActions/GET_NFT_LIST_ERROR';

export {
  GET_COLLECTIONS,
  GET_COLLECTIONS_SUCCESS,
  GET_COLLECTIONS_ERROR,
  CREATE_COLLECTION_SUCCESS,
  CREATE_COLLECTION_ERROR,
  GET_SINGLE_COLLECTION_ERROR,
  GET_SINGLE_COLLECTION_SUCCESS,
  GET_COLLECTIONS_STATISTICS_SUCCESS,
  GET_COLLECTIONS_STATISTICS_ERROR,
  GET_COLLECTION_NFTS_SUCCESS,
  GET_COLLECTION_NFTS_ERROR,
  GET_COLLECTION_NFT_SUCCESS,
  GET_COLLECTION_NFT_ERROR,
  CREATE_COLLECTION,
  GET_SINGLE_COLLECTION,
  GET_COLLECTIONS_STATISTICS,
  GET_COLLECTION_NFTS,
  GET_COLLECTION_NFT,
  GET_TOP_5_COLLECTIONS,
  GET_TOP_5_COLLECTIONS_SUCCESS,
  GET_TOP_5_COLLECTIONS_ERROR,
  GET_COLLECTION_LIST,
  GET_COLLECTION_LIST_SUCCESS,
  GET_COLLECTION_LIST_ERROR,
  GET_NFT_LIST,
  GET_NFT_LIST_SUCCESS,
  GET_NFT_LIST_ERROR,
};
