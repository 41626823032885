import { createGlobalState } from 'react-hooks-global-state';
import { applyMiddleware, createStore } from 'redux';
import createSagaMiddleware from 'redux-saga';
import reducer from './reducers';
import rootSaga from './sagas';

const { getGlobalState, useGlobalState, setGlobalState } = createGlobalState({
  boxModal: 'scale-0',
  boxModalType: null,
  modalPosition: { top: '50%', left: '50%' },
  bidBox: 'scale-0',
  offerModal: 'scale-0',
  priceModal: 'scale-0',
  connectedAccount: null,
  balance: '',
  collections: [],
  bidders: [],
  auctions: [],
  auction: null,
  currentUser: false,
  group: null,
  contract: null,
  nftCardData: null,
  nftSuccessData: null,
  redirect: null,
  platform: null,
  collectionWasCreatedInPage: false,
  provider: null,
});

const truncate = (text, startChars, endChars, maxLength) => {
  if (text?.length > maxLength) {
    let start = text.substring(0, startChars);
    const end = text.substring(text?.length - endChars, text?.length);
    while (start?.length + end?.length < maxLength) {
      start = start + '.';
    }
    return start + end;
  }
  return text;
};

const truncateAddress = (hash) =>
  hash
    ? `${hash.slice(0, 6)}...${hash.slice(hash.length - 4, hash.length)}`
    : '-';

const getImgUrlFromIPFSHash = (hash) =>
  hash ? `https://gateway.pinata.cloud/ipfs/${hash}` : '';

function truncateCustom(number, startLength = 4, endLength = 3, maxLength = 7) {
  const numberString = number?.toString();
  const ellipsis = '...';

  if (numberString?.length <= maxLength) {
    return numberString;
  }

  const regex = new RegExp(`^(.{${startLength}}).*(.{${endLength}})$`);
  const match = numberString.match(regex);

  if (match) {
    return `${match[1]}${ellipsis}${match[2]}`;
  } else {
    return numberString; // Return as is if no match is found
  }
}

const convertToSeconds = (minutes, hours, days) => {
  const seconds = minutes * 60 + hours * 3600 + days * 86400;
  const timestamp = new Date().getTime();
  return timestamp + seconds;
};
const saga = createSagaMiddleware();

const store = createStore(reducer, applyMiddleware(saga));
saga.run(rootSaga);
export {
  store,
  getGlobalState,
  useGlobalState,
  setGlobalState,
  truncate,
  convertToSeconds,
  truncateAddress,
  truncateCustom,
  getImgUrlFromIPFSHash,
};
