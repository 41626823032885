export const expirationDates = [
  {
    title: '1 Day',
    value: 1,
  },
  {
    title: '7 Days',
    value: 7,
  },
  {
    title: '1 Month',
    value: 30,
  },
  {
    title: '3 Months',
    value: 90,
  },
];
