import { collectionApi } from '../../services/api';
import { collectionTypes } from '../types';
import { call, put, takeEvery } from 'redux-saga/effects';

const fetchCollection = function* () {
  try {
    const data = yield call(collectionApi.getAllCollections);
    yield put({ type: collectionTypes.GET_COLLECTIONS_SUCCESS, payload: data });
  } catch (error) {
    yield put({ type: collectionTypes.GET_COLLECTIONS_ERROR, payload: error });
  }
};
const createCollection = function* (action) {
  try {
    const data = yield call(collectionApi.createCollection, action.payload);
    yield put({
      type: collectionTypes.CREATE_COLLECTION_SUCCESS,
      payload: data,
    });
  } catch (error) {
    yield put({
      type: collectionTypes.CREATE_COLLECTION_ERROR,
      payload: error,
    });
  }
};
const getCollectionByAddress = function* (action) {
  try {
    const data = yield call(
      collectionApi.getCollectionByAddress,
      action.payload,
    );
    yield put({
      type: collectionTypes.GET_SINGLE_COLLECTION_SUCCESS,
      payload: data,
    });
  } catch (error) {
    yield put({
      type: collectionTypes.GET_SINGLE_COLLECTION_ERROR,
      payload: error,
    });
  }
};
const getCollectionStatisticsByAddress = function* (action) {
  try {
    const data = yield call(
      collectionApi.getCollectionStatisticsByAddress,
      action.payload,
    );
    yield put({
      type: collectionTypes.GET_COLLECTIONS_STATISTICS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    yield put({
      type: collectionTypes.GET_COLLECTIONS_STATISTICS_ERROR,
      payload: error,
    });
  }
};
const getNFTsByCollectionAddress = function* (action) {
  try {
    const data = yield call(
      collectionApi.getNFTsByCollectionAddress,
      action.payload,
    );
    yield put({
      type: collectionTypes.GET_COLLECTION_NFTS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    yield put({
      type: collectionTypes.GET_COLLECTION_NFTS_ERROR,
      payload: error,
    });
  }
};
const getCollectionNFTByAddressAndNFTId = function* (action) {
  try {
    const data = yield call(
      collectionApi.getCollectionNFTByAddressAndNFTId,
      action.payload,
    );
    yield put({
      type: collectionTypes.GET_COLLECTION_NFT_SUCCESS,
      payload: data,
    });
  } catch (error) {
    yield put({
      type: collectionTypes.GET_COLLECTION_NFT_ERROR,
      payload: error,
    });
  }
};
/**
 *   getTop5Collections,
    getCollectionList
 */
const getTop5Collections = function* () {
  try {
    const data = yield call(collectionApi.getTop5Collections);
    yield put({
      type: collectionTypes.GET_TOP_5_COLLECTIONS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    yield put({
      type: collectionTypes.GET_TOP_5_COLLECTIONS_ERROR,
      payload: error,
    });
  }
};
const getCollectionList = function* (action) {
  try {
    const data = yield call(collectionApi.getCollectionList, action.payload);
    yield put({
      type: collectionTypes.GET_COLLECTION_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    yield put({
      type: collectionTypes.GET_COLLECTION_LIST_ERROR,
      payload: error,
    });
  }
};
//getNFTList
const getNFTList = function* (action) {
  try {
    const data = yield call(collectionApi.getNFTList, action.payload);
    yield put({ type: collectionTypes.GET_NFT_LIST_SUCCESS, payload: data });
  } catch (error) {
    yield put({ type: collectionTypes.GET_NFT_LIST_ERROR, payload: error });
  }
};
const collectionSaga = function* () {
  yield takeEvery(collectionTypes.GET_COLLECTIONS, fetchCollection);
  yield takeEvery(collectionTypes.CREATE_COLLECTION, createCollection);
  yield takeEvery(
    collectionTypes.GET_SINGLE_COLLECTION,
    getCollectionByAddress,
  );
  yield takeEvery(
    collectionTypes.GET_COLLECTIONS_STATISTICS,
    getCollectionStatisticsByAddress,
  );
  yield takeEvery(
    collectionTypes.GET_COLLECTION_NFTS,
    getNFTsByCollectionAddress,
  );
  yield takeEvery(
    collectionTypes.GET_COLLECTION_NFT,
    getCollectionNFTByAddressAndNFTId,
  );
  yield takeEvery(collectionTypes.GET_TOP_5_COLLECTIONS, getTop5Collections);
  yield takeEvery(collectionTypes.GET_COLLECTION_LIST, getCollectionList);
  yield takeEvery(collectionTypes.GET_NFT_LIST, getNFTList);
};
export default collectionSaga;
