import { useGlobalState } from '../../../store';

const StorylinesPopup = () => {
  const [boxModal] = useGlobalState('boxModal');
  // const closeModal = () => {
  //   setGlobalState('boxModal', 'scale-0');
  //   setGlobalState('boxModalType', null);
  // };

  return (
    <div
      className={`fixed top-0 left-0 w-screen h-screen flex items-center
        justify-center bg-[#1D1F24CC] bg-opacity-30 transform
        transition-transform duration-300 z-50 ${boxModal}`}
    >
      <div className="flex flex-col items-center w-[460px] pt-[40px] p-[20px] border border-whiteBorder rounded-[20px]">
        <span className="font-archivo text-white text-center font-semibold text-[30px] leading-[36px]">
          Something special <br /> is coming
        </span>
        {/* href="https://t.me/llaigame2024bot"
        target="_blank" */}
      </div>
    </div>
  );
};

export default StorylinesPopup;
