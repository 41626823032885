import { Contract, ethers } from 'ethers';
import abi from '../../abis/src/NFTFabric/NFTFabric.json';

const CONTRACT_ADDRESS = process.env.NFT_FABRIC_CONTRACT_ADDRESS;

const getEthereumContract = async (walletProvider) => {
  try {
    const provider = await new ethers.providers.Web3Provider(walletProvider);
    const signer = await provider.getSigner();
    const contract = await new Contract(CONTRACT_ADDRESS, abi, signer);

    return contract;
  } catch (e) {
    return e;
  }
};

export const createNewCollection = async ({
  name,
  symbol,
  uri,
  walletProvider,
}) => {
  try {
    const contract = await getEthereumContract(walletProvider);
    const transaction = await contract.deployNewNFT(name, symbol, uri);
    await transaction.wait();
  } catch (e) {
    throw new Error('CREATE NEW COLLECTION ERROR:', e);
  }
};
