import rest from './rest';

const getBidsMadeByAddress = async (address) => {
  const response = await rest.get(`bids/made/${address}`);
  return response.data;
};
const getBidsReceivedByAddress = async (address) => {
  const response = await rest.get(`bids/received/${address}`);
  return response.data;
};
const getBidsByAddressAndNFTId = async ({ address, nftId }) => {
  const response = await rest.get(`bids/${address}/${nftId}`);
  return response.data;
};
export {
  getBidsMadeByAddress,
  getBidsReceivedByAddress,
  getBidsByAddressAndNFTId,
};
