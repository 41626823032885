export const addChain = async () => {
  const test = await window.ethereum?.request({
    method: 'wallet_addEthereumChain',
    params: [
      {
        chainId: process.env.CHAIN_ID,
        rpcUrls: [process.env.RPC_URL],
        chainName: process.env.NETWORK_NAME,
        nativeCurrency: {
          name: 'FTN',
          symbol: 'FTN',
          decimals: 18,
        },
        blockExplorerUrls: [process.env.NEXT_PUBLIC_CHAIN_EXPLORER],
      },
    ],
  });
  return test;
};
