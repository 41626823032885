import collectionSaga from './collectionSaga';
import bidsSaga from './bidsSaga';
import transactionSaga from './transactionSaga';
import userSaga from './userSaga';
import { all, fork } from 'redux-saga/effects';
const rootSaga = function* rootSaga() {
  yield all([
    fork(collectionSaga),
    fork(bidsSaga),
    fork(transactionSaga),
    fork(userSaga),
  ]);
};
export default rootSaga;
