import { bidsTypes } from '../types';
const INIT_STATE = {
  makeList: [],
  receivedlist: [],
  list: [],
  error: null,
  loading: false,
};
const bidsReducer = (state = INIT_STATE, action) => {
  const { type, payload } = action;
  switch (type) {
    case bidsTypes.GET_BIDS_MADE:
      return { ...state, loading: true };
    case bidsTypes.GET_BIDS_MADE_SUCCESS:
      return { ...state, makeList: payload, loading: false };
    case bidsTypes.GET_BIDS_MADE_ERROR:
      return { ...state, error: payload, loading: false };
    case bidsTypes.GET_BIDS_RECEIVED:
      return { ...state, loading: true };
    case bidsTypes.GET_BIDS_RECEIVED_SUCCESS:
      return { ...state, receivedlist: payload, loading: false };
    case bidsTypes.GET_BIDS_RECEIVED_ERROR:
      return { ...state, error: payload, loading: false };
    case bidsTypes.GET_BIDS:
      return { ...state, loading: true };
    case bidsTypes.GET_BIDS_SUCCESS:
      return { ...state, list: payload, loading: false };
    case bidsTypes.GET_BIDS_ERROR:
      return { ...state, error: payload, loading: false };
    default:
      return state;
  }
};

export default bidsReducer;
