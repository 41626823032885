import { userTypes } from '../types';

const INIT_STATE = {
  user: null,
  collection: [],
  collectionsDetailed: [],
  nftOnSale: [],
  listings: [],
  createdNFTs: [],
  ownedNFTs: [],
  error: null,
  loading: true,
};
const usersReducer = (state = INIT_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case userTypes.CREATE_USER:
      return { ...state, loading: true };
    case userTypes.CREATE_USER_SUCCESS:
      return { ...state, user: payload, loading: false };
    case userTypes.CREATE_USER_ERROR:
      return { ...state, error: payload, loading: false };
    case userTypes.GET_USER:
      return { ...state, loading: true };
    case userTypes.GET_USER_SUCCESS:
      return { ...state, user: payload, loading: false };
    case userTypes.GET_USER_ERROR:
      return { ...state, error: payload, loading: false };
    case userTypes.GET_USER_COLLECTION:
      return { ...state, loading: true };
    case userTypes.GET_USER_COLLECTION_SUCCESS:
      return { ...state, collection: payload, loading: false };
    case userTypes.GET_USER_COLLECTION_ERROR:
      return { ...state, error: payload, loading: false };
    case userTypes.GET_USER_COLLECTION_DETAILED:
      return { ...state, loading: true };
    case userTypes.GET_USER_COLLECTION_DETAILED_SUCCESS:
      return { ...state, collectionsDetailed: payload, loading: false };
    case userTypes.GET_USER_COLLECTION_DETAILED_ERROR:
      return { ...state, error: payload, loading: false };
    case userTypes.GET_USER_CREATED_NFTS:
      return { ...state, loading: true };
    case userTypes.GET_USER_CREATED_NFTS_SUCCESS:
      return { ...state, createdNFTs: payload, loading: false };
    case userTypes.GET_USER_CREATED_NFTS_ERROR:
      return { ...state, error: payload, loading: false };
    case userTypes.GET_USER_OWNED_NFTS:
      return { ...state, loading: true };
    case userTypes.GET_USER_OWNED_NFTS_SUCCESS:
      return { ...state, ownedNFTs: payload, loading: false };
    case userTypes.GET_USER_OWNED_NFTS_ERROR:
      return { ...state, error: payload, loading: false };
    case userTypes.GET_USER_ON_SALE_NFTS:
      return { ...state, loading: true };
    case userTypes.GET_USER_ON_SALE_NFTS_SUCCESS:
      return { ...state, nftOnSale: payload, loading: false };
    case userTypes.GET_USER_ON_SALE_NFTS_ERROR:
      return { ...state, error: payload, loading: false };
    case userTypes.GET_USER_LISTINGS:
      return { ...state, loading: true };
    case userTypes.GET_USER_LISTINGS_SUCCESS:
      return { ...state, listings: payload, loading: false };
    case userTypes.GET_USER_LISTINGS_ERROR:
      return { ...state, error: payload, loading: false };
    default:
      return state;
  }
};

export default usersReducer;
