import { setGlobalState } from '@/store';
import { copyToClipboard } from '@/services/copy-to-clipboard';

const NFTCardSettingsPopup = (props) => {
  const shareLink = props?.link || window.location.href;

  const data = [
    !props?.cardData?.listing_id
      ? {
          id: 0,
          label: 'Put on sale',
          onclick: (event) => {
            event.stopPropagation();
            event.preventDefault();
            setGlobalState('boxModalType', 'putOnSale');
            setGlobalState('boxModal', 'scale-100');
            setGlobalState('nftCardData', props.cardData);
          },
        }
      : {
          id: 0,
          label: 'Remove from sale',
          onclick: (event) => {
            event.stopPropagation();
            event.preventDefault();
            setGlobalState('boxModalType', 'removeFromSale');
            setGlobalState('nftCardData', props.cardData);
          },
        },
    {
      id: 1,
      label: (
        <div className="flex gap-[6px] items-center font-semibold text-[14px]">
          <img src="/svg/Copy.svg" alt="" /> Copy link
        </div>
      ),
      onclick: () => copyToClipboard(shareLink),
    },
  ];

  return (
    <div className="bg-[#1D1F2466] p-[8px] rounded-[16px] w-[164px] border-2 border-solid border-[#FFFFFF4D] backdrop-blur mt-[300px]">
      {data
        ?.filter((i) => {
          if (props.cardData.live == true) {
            return i.label !== 'Put on sale';
          } else {
            return i;
          }
        })
        .map((item, index) => {
          return (
            <div className="group" key={item.label}>
              <div
                key={index}
                className="px-[13px] py-[16px] hover:cursor-pointer hover:bg-[#FFFFFF1A] rounded-[12px] font-archivo text-white font-semibold text-[14px] leading-[14px]"
                onClick={(event) => {
                  item.onclick(event);
                  props.setOpen(false);
                }}
              >
                {item.label}
              </div>
              {index === 0 && item.id === 0 && (
                <div className="flex justify-center items-center">
                  <div className="w-[124px] group-hover:w-[124px] h-[1px] bg-[#FFFFFF4D] mt-[6px] mb-[6px]"></div>
                </div>
              )}
            </div>
          );
        })}
    </div>
  );
};

export default NFTCardSettingsPopup;
