import rest from './rest';

const createUser = async (user) => {
  const response = await rest.post(`user`, user);
  return response.data;
};
const getUserByAddress = async (address) => {
  const response = await rest.get(`users/${address}`);
  return response.data;
};
const getUserCollectionByAddress = async (address) => {
  const response = await rest.get(`users/${address}/collections`);
  return response.data;
};
const getUserCollectionDetailedByAddress = async (address) => {
  const response = await rest.get(`user/${address}/collections_detailed`);
  return response.data;
};
const getUserCreatedNFTsByAddress = async (address) => {
  const response = await rest.get(`users/created/${address}/nfts`);
  return response.data;
};
const getUserOwnedNFTsByAddress = async (address) => {
  const response = await rest.get(`users/owned/${address}/nfts`);
  return response.data;
};
const getUserOnSaleNFTsByAddress = async (address) => {
  const response = await rest.get(`users/owned/${address}/nfts?status=listed`);
  return response.data;
};
const getUserListingsByAddress = async (address) => {
  const response = await rest.get(`user/${address}/listings`);
  return response.data;
};
export {
  createUser,
  getUserByAddress,
  getUserCollectionByAddress,
  getUserCreatedNFTsByAddress,
  getUserOwnedNFTsByAddress,
  getUserCollectionDetailedByAddress,
  getUserOnSaleNFTsByAddress,
  getUserListingsByAddress,
};
