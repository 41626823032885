import rest from './rest';

const getAllCollections = async () => {
  const response = await rest.get(`collection`);
  return response.data;
};
const getTop5Collections = async () => {
  const response = await rest.get(`collections/top_five`);
  return response.data;
};

const createCollection = async (data) => {
  const response = await rest.post(`collection`, data);
  return response.data;
};
const getCollectionByAddress = async (address) => {
  try {
    const response = await rest.get(`collection/${address}`);
    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    return 'axiosError';
  }
};
const getCollectionStatisticsByAddress = async (address) => {
  try {
    const response = await rest.get(`collection/statistic/${address}`);
    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    return 'axiosError';
  }
};
const getNFTsByCollectionAddress = async ({
  address,
  minPrice,
  maxPrice,
  sortBy,
  listed,
}) => {
  const response = await rest.get(
    `collection/${address}/nfts?${sortBy ? `sort_by=${sortBy}` : ''}${
      minPrice ? `&min_price=${minPrice}` : ''
    }${maxPrice ? `&max_price=${maxPrice}` : ''}${
      listed ? `&listed=${listed}` : ''
    }`,
  );
  return response.data;
};
const getCollectionList = async ({ minPrice, maxPrice, sortBy, listed }) => {
  const response = await rest.get(
    `collections_list?${minPrice ? `floor_price_min=${minPrice}&` : ''}${
      maxPrice ? `floor_price_max=${maxPrice}&` : ''
    }${sortBy ? `sort_by=${sortBy}&` : ''}${listed ? `listed=${listed}` : ''}`,
  );
  return response.data;
};
const getCollectionNFTByAddressAndNFTId = async ({ address, nftId }) => {
  try {
    const response = await rest.get(`collection/${address}/nft/${nftId}`);
    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    return 'axiosError';
  }
};

const getNFTList = async ({ minPrice, maxPrice, sortBy, search, listed }) => {
  const response = await rest.get(
    `nft_list?${minPrice ? `price_min=${minPrice}&` : ''}${
      maxPrice ? `price_max=${maxPrice}&` : ''
    }${sortBy ? `sort_by=${sortBy}&` : ''}${search ? `search=${search}&` : ''}${
      listed ? `listed=${listed}` : ''
    }`,
  );
  return response.data;
};

export {
  getAllCollections,
  createCollection,
  getCollectionByAddress,
  getCollectionStatisticsByAddress,
  getNFTsByCollectionAddress,
  getCollectionNFTByAddressAndNFTId,
  getTop5Collections,
  getCollectionList,
  getNFTList,
};
