import React from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import SelectCalendarTime from './SelectCalendarTime';

const CustomCalendar = (props) => {
  const formatShortWeekday = (_, date) => {
    const weekdays = ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'];
    return weekdays[date.getDay()];
  };

  const formatMonthYear = (locale, date) => {
    return date.toLocaleDateString(locale, { month: 'long' });
  };

  return (
    <div className="bg-[#1D1F2466] rounded-[20px] p-[20px] border-2 border-[#FFFFFF4D] bg-opacity-1 backdrop-blur-[60px]">
      <Calendar
        onChange={props.onChange}
        value={props.value}
        locale="en"
        calendarType="gregory"
        formatShortWeekday={formatShortWeekday}
        formatMonthYear={formatMonthYear}
      />
      <SelectCalendarTime
        setChosenDate={props.setChosenDate}
        chosenDate={props.chosenDate}
      />
      <div className="flex gap-[16px]">
        <button
          className="w-full rounded-[18px] bg-white py-[20px] text-center font-spaceGrotesk text-black font-semibold text-[16px] leading-[16px] border-2 border-white hover:bg-[#0E0E0E] hover:border-white hover:text-white transition duration-300 ease-in-out"
          onClick={() => {
            props.setExpirationDateValue(null);
          }}
        >
          Apply
        </button>
        <button
          className="w-full rounded-[18px] bg-[#0E0E0E] py-[20px] text-center font-spaceGrotesk text-white font-semibold text-[16px] leading-[16px] border-2 border-[#0E0E0E] hover:border-white transition duration-300 ease-in-out"
          onClick={() => props.setExpirationDateValue(1)}
        >
          Cancel
        </button>
      </div>
    </div>
  );
};

export default CustomCalendar;
