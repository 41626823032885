import { collectionTypes } from '../types';

const INIT_STATE = {
  list: [],
  error: null,
  single: null,
  statistics: null,
  nfts: [],
  nft: null,
  loading: true,
  top5List: [],
  nftList: [],
};

const collectionReducer = (state = INIT_STATE, action) => {
  const { type, payload } = action;
  switch (type) {
    case collectionTypes.GET_COLLECTIONS:
      return { ...state, loading: true };
    case collectionTypes.GET_COLLECTIONS_SUCCESS:
      return { ...state, list: payload, loading: false };
    case collectionTypes.GET_COLLECTIONS_ERROR:
      return { ...state, error: payload, loading: false };
    case collectionTypes.CREATE_COLLECTION:
      return { ...state, loading: true };
    case collectionTypes.CREATE_COLLECTION_SUCCESS:
      return { ...state, list: [...state.list, payload], loading: false };
    case collectionTypes.CREATE_COLLECTION_ERROR:
      return { ...state, error: payload, loading: false };
    case collectionTypes.GET_SINGLE_COLLECTION:
      return { ...state, loading: true };
    case collectionTypes.GET_SINGLE_COLLECTION_SUCCESS:
      return { ...state, single: payload, loading: false };
    case collectionTypes.GET_SINGLE_COLLECTION_ERROR:
      return { ...state, error: payload, loading: false };
    case collectionTypes.GET_COLLECTIONS_STATISTICS:
      return { ...state, loading: true };
    case collectionTypes.GET_COLLECTIONS_STATISTICS_SUCCESS:
      return { ...state, statistics: payload, loading: false };
    case collectionTypes.GET_COLLECTIONS_STATISTICS_ERROR:
      return { ...state, error: payload, loading: false };
    case collectionTypes.GET_COLLECTION_NFTS:
      return { ...state, loading: true };
    case collectionTypes.GET_COLLECTION_NFTS_SUCCESS:
      return { ...state, nfts: payload, loading: false };
    case collectionTypes.GET_COLLECTION_NFTS_ERROR:
      return { ...state, error: payload, loading: false };
    case collectionTypes.GET_COLLECTION_NFT:
      return { ...state, loading: true };
    case collectionTypes.GET_COLLECTION_NFT_SUCCESS:
      return { ...state, nft: payload, loading: false };
    case collectionTypes.GET_COLLECTION_NFT_ERROR:
      return { ...state, error: payload, loading: false };
    case collectionTypes.GET_TOP_5_COLLECTIONS:
      return { ...state, loading: true };
    case collectionTypes.GET_TOP_5_COLLECTIONS_SUCCESS:
      return { ...state, top5List: payload, loading: false };
    case collectionTypes.GET_TOP_5_COLLECTIONS_ERROR:
      return { ...state, error: payload, loading: false };
    case collectionTypes.GET_COLLECTION_LIST:
      return { ...state, loading: true };
    case collectionTypes.GET_COLLECTION_LIST_SUCCESS:
      return { ...state, list: payload, loading: false };
    case collectionTypes.GET_COLLECTION_LIST_ERROR:
      return { ...state, error: payload, loading: false };
    case collectionTypes.GET_NFT_LIST:
      return { ...state, loading: true };
    case collectionTypes.GET_NFT_LIST_SUCCESS:
      return { ...state, nftList: payload, loading: false };
    case collectionTypes.GET_NFT_LIST_ERROR:
      return { ...state, error: payload, loading: false };
    default:
      return state;
  }
};

export default collectionReducer;
